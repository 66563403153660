import { configBuilder } from "ccui-framework";
import { commonConfig } from "../../../src/config/partner";

const Config = {
  landrys: {
    ...commonConfig,
    layoutVersion: 1,
    reduxLogger: false,
    // Verification
    verificationType: "tlo",
    iconBig1: "https://cdn.cardcash.com/website/partners/landrys/icon_big1.png",
    iconBig2: "https://cdn.cardcash.com/website/partners/landrys/icon_big2.png",
    iconBig3: "https://cdn.cardcash.com/website/partners/landrys/icon_big3.png",
    headerLogoBrand:
      "https://cdn.cardcash.com/website/partners/landrys/logo.png",
    headerLogoCC:
      "https://cdn.cardcash.com/website/partners/common/card_cash_logo.png",
    headerLogoBrandIcon:
      "https://cdn.cardcash.com/website/partners/landrys/logo.png",
    headerLogoCCIcon:
      "https://cdn.cardcash.com/website/partners/common/card_cash_logo_icon.png",
    showTermsConditions: true,
    partnerBrand: "Landry's",
    partnerURL: "http://www.landrysinc.com",
    partnerEmail: "support@cardcash.com",
    giftCardTerms: "http://www.landrysinc.com/giftcards/terms.asp",
    paymentType: "eGift Card",
    aOrAn: "a",
    twitterURL: "https://twitter.com/landrysinc?lang=en",
    facebookURL: "https://www.facebook.com/landrysinc/",
    youTubeURL: null,
    instagramURL: "https://www.instagram.com/landrys_restaurants_inc/",
    googleURL: null,
    linkedInURL: null,
    pinterestURL: null,
    googleAnalytics: "UA-71288742-1",
    x_cc_app: "5ZTX4z4z5",
    baseURL: "https://production-api.cardcash.com/",
    iconThreeText: "Receive an eGift Card",

    // header
    headerLogoOrder: ["headerLogoCC", "headerLogoBrand"],
    headerTextPartOne: "Exchange Gift Cards for a",
    headerTextPartTwo: " Landry's eGift Card",
    secHeaderText: "Over 200 Merchants Accepted!",
    bottomLinkText: "Redeem your eGift Card at",
    headerContactLinkText: "Gift Card Help",
    sellBtnText: "GET MY EGIFT CARD",
    offerButtonTxt: "GET OFFER",
    totalText: "Total Offer",

    stepsHideBottomOn: ["steptwo", "payment", "order-complete"], // Needs a better name
    detailStepOneText: {
      header: "Get Offer",
      text: "Enter merchant name and card balance for a quick exchange offer. In seconds, see how much you can get in exchange for your gift card.",
    },
    detailStepTwoText: {
      header: "Enter Information",
      text: "Enter card number and pin and some basic customer information. Once your order is placed, your card balance will be verified behind the scenes to ensure the fastest and most secure process possible.",
    },
    detailStepThreeText: {
      header: "Receive a Landry's eGift Card",
      iconText: "Receive an eGift Card",
      text: "Your eGift Card will be emailed to you in a printable format once your order has been approved. Approval time can take up to 1 business day.",
    },
    cardCashLogoFirst: true,
  },

  "test-landrys": {
    devMode: "integration",
    googleAnalytics: "",
    x_cc_app: "a45TCjLtf",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },

  localhost: {
    devMode: "development",
    googleAnalytics: "",
    x_cc_app: "a45TCjLtf",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("landrys", Config);
